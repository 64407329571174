






import {Vue, Component} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import FinCENService from "@/services/request/FinCENService";

const AppModule = namespace("App");

@Component({})
export default class CreateFRSO extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  create(){
    this.startLoading();
    FinCENService.createOffers().then(
        ok => {
          console.log("created")
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

}
