import { render, staticRenderFns } from "./CreateFRSO.vue?vue&type=template&id=6dc8c932&scoped=true&"
import script from "./CreateFRSO.vue?vue&type=script&lang=ts&"
export * from "./CreateFRSO.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc8c932",
  null
  
)

export default component.exports